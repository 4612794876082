<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <icon-logo />
          </div>
        </div>
        <k-form ref="form" :validator="loginFormValidator" @submit="login">
          <login-form />
        </k-form>
        <vue-recaptcha
          hidden="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="resetCaptcha"
          @error="errorMethod"
          size="invisible"
          :sitekey="$recaptchaSiteKey"
        >
        </vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
import { isAccessibleToAdminDashboard } from '@/modules/roles/utils';
import loginForm from './loginForm';
import { loginFormValidator } from '@/plugins/validator';

export default {
  name: 'login-page',
  components: {
    loginForm
  },
  data: function () {
    return {
      loginFormValidator,
      entity: {
        email: null,
        password: null
      }
    };
  },
  mounted() {
    if (this.$route.query.share != null) {
      this.$store.commit('updateShareStatus', true);
      this.$store.commit('updateShareViewLink', this.$route.query.share);
    } else {
      this.$store.commit('updateShareStatus', false);
      this.$store.commit('updateShareViewLink', null);
    }

    if (this.$store.getters.isAuthenticated) {
      if (isAccessibleToAdminDashboard(this.$store.state.principal)) {
        this.$router.push('/administrator/tenant');
      } else {
        this.$router.push(
          '/dashboard' +
            (this.$store.state.shareViewLink != null
              ? '?share=' + this.$store.state.shareViewLink
              : '')
        );
      }
    } else {
      this.$store.commit('logout');
    }
  },
  methods: {
    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.$store
        .dispatch('loginv2', {
          email: this.entity.email,
          password: this.entity.password,
          recaptchaToken: token
        })
        .then((result) => {
          let res = result.response.data;
          if (res.supportVarify) {
            this.$router.push('/verification');
          } else {
            let notification = {};
            notification.title = 'Notification';
            notification.content = res.errorMessage;
            this.$store.commit('showNotification', notification);
          }
        });
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    errorMethod() {
      console.log('reCaptcha Error');
    },
    login(dataItem) {
      if (this.$refs.form.isValid()) {
        if (!this.$refs.recaptcha.widgetId) this.resetCaptcha();
        this.$refs.recaptcha.execute();
        this.entity = dataItem;
      }
    }
  }
};
</script>
