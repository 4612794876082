import { mapMutations } from 'vuex';
import getUserSubscriptionMixin from '@/components/tenantAdmin/mixins/getUserSubscription';
import { setLucaCookies } from '@/shared/utils/luca';
import {
  isAccessibleToAccessScreen,
  isAdmin,
  isTenantAdministrator
} from '@/modules/roles/utils';

export default {
  mixins: [getUserSubscriptionMixin],
  methods: {
    ...mapMutations('fastspring', ['SET_IS_FIRST_TENANT_ADMIN_LOGIN']),
    async redirectAfterLogin(result) {
      const res = result.response.data;

      if (!res.idToken) {
        const notification = {
          title: 'Notification',
          content: res.message
        };

        this.$store.commit('showNotification', notification);
        return;
      }

      const { principal, fastspring } = this.$store.state;

      if (principal.firstLogin) {
        this.$router.push('/createPassword');
        return;
      }

      if (isAdmin(principal)) {
        this.$store.commit('agreement');
        this.$router.push('/administrator/tenant');
        return;
      }

      if (isTenantAdministrator(principal)) {
        let url = '/manageUsers';

        if (fastspring.isFirstTenantAdminLogin) {
          url = '/payment';
          fastspring.isFirstTenantAdminLogin = false;
        }

        await this.getSubscriptionInfo();

        // User already subscribed => Redirect to dashboard instead of user management
        if (fastspring?.subscription?.fastspringSubscriptionId) {
          url = '/dashboard';

          if (principal.lucaAccessible) {
            url = '/access';
            setLucaCookies(
              this.$store.state.principal.idToken,
              this.$store.state.principal.lucaAccessible
            );
          }
        }

        this.$store.commit('agreement');
        this.$router.push(url);

        return;
      }

      this.$router.push('/termsAndConditions');
    },

    redirectAfterTnCAgreement() {
      const { principal } = this.$store.state;

      if (isAccessibleToAccessScreen(principal)) {
        this.$router.push('/access');
        return;
      }

      this.redirectToDashboard();
    },

    redirectToDashboard() {
      this.$router.push(
        '/dashboard' +
          (this.$store.state.shareViewLink != null
            ? '?share=' + this.$store.state.shareViewLink
            : '')
      );
    }
  }
};
