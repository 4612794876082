<template>
  <k-chart :style="{ height: size }" :renderAs="'canvas'" :transitions="false">
    <k-chart-area :background="'transparent'" />
    <k-chart-tooltip :visible="true" />
    <k-chart-legend :visible="true" />
    <k-chart-series>
      <k-chart-series-item
        :type="'area'"
        :data-items="data"
        :line="{ style: smoothLine }"
        :markers="{ visible: false }"
        :color="color"
        :stack="false"
      ></k-chart-series-item>
    </k-chart-series>
    <k-chart-valueaxis>
      <k-chart-valueaxis-item
        :majorGridLines="{ visible: false }"
        :visible="false"
      />
    </k-chart-valueaxis>
    <k-chart-categoryaxis>
      <k-chart-categoryaxis-item
        :color="'#909090'"
        :majorGridLines="{ visible: false }"
        :majorTicks="{ visible: false }"
        :minorTicks="{ visible: false }"
        :categories="category"
        :visible="true"
        :labels="{
          visible: true,
          font: this.fontSize + ' lato',
          step: Math.round(category.length / 5) - 1,
          template
        }"
        :notes="{
          line: {
            length: pointerLine,
            width: 2,
            color: '#3C3CE8'
          },
          icon: {
            background: '#3C3CE8',
            size: 10
          },
          data: [
            {
              value: (point < 1 ? 1 : point) - 1
            }
          ]
        }"
      />
    </k-chart-categoryaxis>
  </k-chart>
</template>
<script>
export default {
  props: {
    data: Array,
    color: String,
    size: String,
    category: Array,
    point: Number,
    pointerLine: Number,
    fontSize: String
  },
  data() {
    return {
      smoothLine: 'smooth'
    };
  },
  mounted() {
    if (this.point) {
      ///
    }
  },
  methods: {
    template(e) {
      return `${e.value * 100}%`;
    }
  }
};
</script>
