<template>
  <div class="button-container">
    <button class="fade-in" @click="accessDashboard">
      <div>
        <img src="/chart.png" :width="60" :height="60" alt="" />
        <br />
        Access <br />Dashboard
      </div>
    </button>
    <button class="fade-in" @click="accessLuca" v-if="showLucaLink">
      <div>
        <img src="/favicon.ico" :width="60" :height="60" alt="" />
        <br />Access <br />Luca
      </div>
    </button>
    <button class="fade-in" @click="accessAdminDashboard" v-if="showAdminLink">
      <div>
        <img src="/favicon.ico" :width="60" :height="60" alt="" />
        <br />Access <br />Admin
      </div>
    </button>
  </div>
</template>

<script>
import { getUrl } from '@/shared/utils/luca';
import loginRedirectMixin from '@/shared/mixins/loginRedirect';
import {
  isAccessibleToAdminDashboard,
  isLucaAccessible
} from '@/modules/roles/utils';

export default {
  name: 'AccessComponent',
  mixins: [loginRedirectMixin],
  methods: {
    accessDashboard() {
      this.redirectToDashboard();
    },
    accessLuca() {
      window.location.href = getUrl('luca');
    },
    accessAdminDashboard() {
      this.$router.push('/administrator/tenant');
    }
  },
  computed: {
    showLucaLink() {
      return isLucaAccessible(this.$store.state.principal);
    },
    showAdminLink() {
      return isAccessibleToAdminDashboard(this.$store.state.principal);
    }
  }
};
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  button {
    padding: 0;
    margin: 0 40px;
    color: var(--body-text);
    border-radius: 16px;
    font-size: 20px;
    line-height: 24px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    position: relative;

    img {
      margin-bottom: 8px;
    }

    div {
      background-color: var(--component-bg);
      border-radius: 14px;
      padding: 20px 40px;
      margin: 2px;
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 2;
      color: var(--button-text);

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}
</style>
