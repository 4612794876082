<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <icon-logo />
          </div>
        </div>
        <k-form
          ref="form"
          :validator="mfaCodeValidator"
          @submit="mfaSignIn"
          autocomplete="off"
        >
          <multi-factor-authentication-form v-on:resendCode="resendCode" />
        </k-form>
        <vue-recaptcha
          hidden="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="resetCaptcha"
          @error="errorMethod"
          size="invisible"
          :sitekey="$recaptchaSiteKey"
        >
        </vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
import { isAccessibleToAdminDashboard } from '@/modules/roles/utils';
import multiFactorAuthenticationForm from './multiFactorAuthenticationForm';
import { mfaCodeValidator } from '@/plugins/validator';

export default {
  components: {
    multiFactorAuthenticationForm
  },
  data: function () {
    return {
      mfaCodeValidator
    };
  },
  mounted() {
    this.$refs.form.$el.setAttribute('autocomplete', 'off');
  },
  methods: {
    onCaptchaVerified(token) {
      this.$store
        .dispatch('login', {
          email: this.$store.state.principal.email,
          password: this.$store.state.principal.password,
          recaptchaToken: token
        })
        .then((result) => {
          if (result.response.status == 200) {
            this.$store.state.notification.show = true;
            this.$store.state.notification.title = 'success';
            this.$store.state.notification.content = 'SUCCESS_RESEND_CODE';
          }
        });
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    resendCode() {
      this.$refs.recaptcha.execute();
    },
    errorMethod() {
      console.log('reCaptcha Error');
    },
    mfaSignIn(dataItem) {
      if (this.$refs.form.isValid()) {
        this.$store
          .dispatch('mfaSignIn', {
            code: dataItem.code
          })
          .then((result) => {
            let res = result.response.data;
            if (res.idToken) {
              if (this.$store.state.principal.firstLogin) {
                this.$router.push('/createPassword');
              } else if (
                isAccessibleToAdminDashboard(this.$store.state.principal)
              ) {
                this.$store.commit('agreement');
                this.$router.push('/administrator/tenant');
              } else {
                this.$router.push('/termsAndConditions');
              }
            } else {
              let notification = {};
              notification.title = 'Notification';
              notification.content = res.message;
              this.$store.commit('showNotification', notification);
            }
          });
      }
    }
  }
};
</script>
