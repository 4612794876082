import { isAccessibleToAdminDashboard, isAdmin, isSale } from '../utils';

export const ROLES = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  SALE: 'SALE',
  TENANT_ADMINISTRATOR: 'TENANT_ADMINISTRATOR',
  USER: 'USER'
};

export const ROLE_OPTIONS = [
  {
    label: 'User',
    value: ROLES.USER
  },
  {
    label: 'Sale',
    value: ROLES.SALE
  }
];

export const TRANSPARENTLY_EMAIL_DOMAINS = ['transparently.ai', 'tly.ai'];

export const getAdminRoutes = (principal) => [
  {
    href: '/dashboard',
    label: 'Dashboard',
    show: isSale(principal)
  },
  {
    href: '/administrator/tenant',
    label: 'Manage Tenants',
    show: isAccessibleToAdminDashboard(principal)
  },
  {
    href: '/administrator/tenant-request',
    label: 'Tenants Requests',
    show: isAccessibleToAdminDashboard(principal)
  },
  {
    href: '/administrator/user-request',
    label: 'Users Requests',
    show: isAccessibleToAdminDashboard(principal)
  },
  {
    href: '/administrator/announcement',
    label: 'Announcements',
    show: isAdmin(principal)
  },
  {
    href: '/administrator/termandcondition',
    label: 'Terms & Conditions',
    show: isAdmin(principal)
  },
  {
    href: '/administrator/setting',
    label: 'Settings',
    show: isAdmin(principal)
  }
];
