import { ROLES } from '../constants';

export const isAccessibleToAdminDashboard = (principal) =>
  isAdmin(principal) || isSale(principal);

export const isAdmin = (principal) => principal.administrator;
export const isSale = (principal) => principal?.role === ROLES.SALE;
export const isTenantAdministrator = (principal) =>
  principal?.tenantAdministrator;
export const isUser = (principal) => principal?.role === ROLES.USER;

export const isLucaAccessible = (principal) => principal?.lucaAccessible;

export const isAccessibleToAccessScreen = (principal) => {
  return isLucaAccessible(principal) || isSale(principal);
};
