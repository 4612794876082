<template>
  <div>
    <div class="row header mb-2">
      <div class="col-4 d-flex align-items-center">
        <k-button
          class="unset-min-width k-button-sm k-rounded-md k-icon-button me-2"
          theme-color="secondary"
          fill-mode="outline"
          @click="$router.go(-1)"
        >
          <span class="k-icon k-i-arrow-chevron-left"></span>
        </k-button>
        <span>{{ tenantId.split('-')[0] }}</span>
      </div>
      <div class="col-8 d-flex justify-content-end align-items-center">
        Users Limit: {{ userLimit }}
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showUpdateUsersLimitPopup = true"
        >
          Set Users limit
        </k-button>
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showDialog"
        >
          Add User
        </k-button>

        <div class="ms-2">
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showResetPasswordConfirmation()"
              :disabled="selectedItems.length === 0"
            >
              Reset Password
            </k-button>

            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showDeleteConfirmationDialog()"
              :disabled="selectedItems.length === 0"
              >Delete User</k-button
            >

            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showBatchEditStatusDialog()"
              :disabled="selectedItems.length === 0"
              >Edit Status</k-button
            >
          </actions-button>
        </div>
      </div>
    </div>
    <k-grid
      :data-items="dataResult"
      :pageable="pageable"
      :sortable="sortable"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
      :selected-field="selectedField"
      @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange"
    >
      <template v-slot:statusTemplate="{ props }">
        <td
          :class="!props.dataItem.disabled ? 'k-text-success' : 'k-text-error'"
        >
          {{ !props.dataItem.disabled ? 'Active' : 'Disabled' }}
        </td>
      </template>
      <template v-slot:role="{ props }">
        <td>
          <p class="mb-0">
            {{ roleName(props.dataItem) }}
          </p>
        </td>
      </template>
      <template v-slot:lastLoginDate="{ props }">
        <td>
          <p v-if="props.dataItem.lastLoginDate" class="mb-0">
            {{ formatDate(props.dataItem.lastLoginDate) }} <br />
            {{ formatTime(props.dataItem.lastLoginDate) }}
          </p>
        </td>
      </template>
      <template v-slot:lucaAccessible="{ props }">
        <td>
          <p class="mb-0">
            {{ props.dataItem.lucaAccessible ? 'Yes' : 'No' }}
          </p>
        </td>
      </template>
      <template v-slot:actionTemplate="{ props }">
        <td class="d-flex align-items-center">
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="openPopupConfirmSetAsTenantManager(props.dataItem)"
              v-if="!isTenantManager(props.dataItem)"
            >
              Set as Tenant Manager
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditStatusDialog(props.dataItem)"
            >
              Edit Status
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditRoleDialog(props.dataItem)"
              v-if="
                isTransparentlyEmail(props.dataItem.email) && isCurrentUserAdmin
              "
            >
              Edit Role
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditDialog(props.dataItem)"
              >Edit User</k-button
            >
          </actions-button>
        </td>
      </template>
    </k-grid>

    <k-dialog v-if="dialog.show" :title="' '" @close="closeDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit.prevent="save">
        <div class="row" v-if="entity.uid">
          <div class="col-12">
            User ID
            <k-inputs v-model="entity.uid" name="uid" :disabled="true" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            User Name
            <k-inputs
              v-model="entity.displayName"
              name="displayName"
              :required="true"
              placeholder="Eg. John Doe"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            Email
            <k-inputs
              v-model="entity.email"
              name="email"
              :required="true"
              placeholder="Eg. john.doe@mail.com"
              :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            Access to Luca
            <div>
              <k-dropdown-native
                :data-items="lucaAccessOptions"
                :text-field="'text'"
                :data_item-key="'id'"
                :value="entity.lucaAccessible"
                @change="changeLucaAccessible"
              >
              </k-dropdown-native>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog v-if="statusDialog.show" :title="' '" @close="closeStatusDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="statusDialog.title"></div>
      </div>
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-12 mb-3">
            <k-dropdown-native
              :data-items="status"
              :text-field="'text'"
              :data_item-key="'id'"
              :defaultValue="entity.status"
              @change="changeStatus"
            >
            </k-dropdown-native>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeStatusDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog v-if="roleDialog.show" :title="' '" @close="closeRoleDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="roleDialog.title"></div>
      </div>
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-12 mb-3">
            <k-dropdown-native
              :data-items="roleOptions"
              :text-field="'label'"
              :data_item-key="'value'"
              :defaultValue="entity.role"
              @change="changeRole"
            >
            </k-dropdown-native>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeRoleDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog
      v-if="showUpdateUsersLimitPopup"
      :title="' '"
      @close="showUpdateUsersLimitPopup = false"
    >
      <div class="row mb-3">
        <div class="col-12 text-center">Number of Users Limit</div>
      </div>
      <form @submit.prevent="setUserLimit">
        <div class="row">
          <div class="col-12">
            Users Limit
            <k-input-num
              v-model="entityUsersLimit"
              :required="true"
              :spinners="false"
              :min="0"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="showUpdateUsersLimitPopup = false"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <confirm-popup
      :isShowing="deleteConfirmationDialog.show"
      @onClose="closeDeleteConfirmationDialog"
      @onConfirm="deleteUser()"
    >
      <p class="fw-bold text-center">
        Are you sure you want to delete
        {{ selectedItems.map(({ email }) => email).join(', ') }}?
      </p>
    </confirm-popup>

    <confirm-popup
      :isShowing="resetPasswordConfirmationDialog.show"
      @onClose="closeResetPasswordConfirmation"
      @onConfirm="sendLinkResetPassword"
    >
      <p class="fw-bold text-center">
        Are you sure you want to send a password reset link to
        {{ selectedItems.map(({ email }) => email).join(', ') }}?
      </p>
    </confirm-popup>

    <k-dialog
      v-if="batchStatusDialog.show"
      :title="' '"
      @close="closeBatchStatusDialog"
    >
      <div class="row mb-3">
        <div class="col-12 text-center">Edit Status</div>
      </div>
      <form @submit.prevent="batchEditStatus">
        <div class="row">
          <div class="col-12 mb-3">
            <k-dropdown-native
              :data-items="status"
              :text-field="'text'"
              :data_item-key="'id'"
              @change="changeStatus"
            >
            </k-dropdown-native>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeBatchStatusDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <confirm-popup
      :isShowing="notificationDialog.show"
      @onClose="notificationDialog.show = false"
      :hideConfirmButton="true"
    >
      <p class="fw-bold" v-html="notificationDialog.title"></p>
      <p v-html="notificationDialog.message"></p>
    </confirm-popup>

    <confirm-popup
      :isShowing="showSetTenantAdminPopup.show"
      @onClose="showSetTenantAdminPopup.show = false"
      @onConfirm="setUserAsTenantAdmin"
    >
      <p class="fw-bold text-center">
        Are you sure you want to make this user as Tenant Manager of this
        company?
      </p>
    </confirm-popup>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { userFormValidator } from '@/plugins/validator';
import tenantAdminMixins from './mixins/tenantAdmin';
import ConfirmPopup from './components/confirmPopup';
import ActionsButton from './components/actionsButton.vue';
import { format } from 'date-fns';
import {
  ROLE_OPTIONS,
  TRANSPARENTLY_EMAIL_DOMAINS
} from '@/modules/roles/constants';
import { isAdmin } from '@/modules/roles/utils';

export default {
  props: ['tenantId'],
  components: {
    ConfirmPopup,
    ActionsButton
  },
  mixins: [tenantAdminMixins],
  data() {
    return {
      personList: [],
      userLimit: 0,
      entity: {
        uid: '',
        displayName: '',
        disabled: false,
        email: '',
        status: { id: null, text: '' }
      },
      selectedField: 'selected',
      pageable: true,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'uid', dir: 'asc' }],
      filter: null,
      staticColumns: [
        { field: 'uid', title: 'User ID' },
        { field: 'displayName', title: 'User Name' },
        { field: 'email', title: 'Contact Email' },
        {
          field: 'disabled',
          title: 'Status',
          width: 50,
          cell: 'statusTemplate'
        },
        {
          field: '',
          title: 'Last Login Date',
          cell: 'lastLoginDate'
        },
        { field: 'role', title: 'Role', cell: 'role' },
        {
          field: 'lucaAccessible',
          title: 'Luca Accessible',
          cell: 'lucaAccessible'
        },
        { field: '', title: 'Actions', cell: 'actionTemplate', width: 100 }
      ],
      lucaAccessOptions: [
        { id: true, text: 'Yes' },
        { id: false, text: 'No' }
      ],
      gridClickedRow: {},
      status: [
        { id: true, text: 'Active' },
        { id: false, text: 'Disabled' }
      ],
      dialog: {
        show: false,
        title: null
      },
      statusDialog: {
        show: false,
        title: null
      },
      roleDialog: {
        show: false,
        title: null
      },
      resetPasswordConfirmationDialog: {
        show: false
      },
      deleteConfirmationDialog: {
        show: false
      },
      batchStatusDialog: {
        show: false
      },
      notificationDialog: {
        show: false,
        title: null,
        message: null
      },
      userFormValidator,
      showUpdateUsersLimitPopup: false,
      showSetTenantAdminPopup: {
        show: false,
        userId: ''
      },
      entityUsersLimit: 0,
      roleOptions: ROLE_OPTIONS
    };
  },
  mounted() {
    this.getPersonList();
  },
  computed: {
    tenantManager() {
      const tenantManager = this.personList.find(
        ({ isManager }) => !!isManager
      );
      return tenantManager && tenantManager.uid;
    },
    dataResult() {
      return process(this.personList, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });
    },
    areAllSelected() {
      return (
        this.personList.findIndex((item) => item.selected === false) === -1
      );
    },
    columns() {
      return [
        {
          field: 'selected',
          width: '50px',
          headerSelectionValue: this.areAllSelected
        },
        ...this.staticColumns
      ];
    },
    selectedItems() {
      return this.personList.filter((item) => item.selected);
    },
    isCurrentUserAdmin() {
      return isAdmin(this.$store.state.principal);
    }
  },
  methods: {
    changeStatus(event) {
      this.entity.status = event.value;
    },
    changeRole(event) {
      this.entity.role = event.value.value;
    },
    changeLucaAccessible(event) {
      this.entity.lucaAccessible = event.value;
    },
    getPersonList() {
      axios
        .post('/person/list', { tenantId: this.tenantId })
        .then((response) => {
          this.personList = response.data.listUser.map((item) => ({
            ...item,
            selected: false
          }));
          this.userLimit = response.data.limit;
        });
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.personList = this.personList.map((item) => ({
        ...item,
        selected: checked
      }));
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    showDialog() {
      this.dialog.title = 'Add User to ' + this.tenantId;
      this.dialog.show = true;
    },
    showEditDialog(dataItem) {
      this.dialog.title = 'Edit User ' + dataItem.displayName;
      this.entity = this.$root.clone(dataItem);
      this.dialog.show = true;
      this.entity.lucaAccessible = this.lucaAccessOptions.find(
        (x) => x.id == dataItem.lucaAccessible
      );
    },
    showEditStatusDialog(dataItem) {
      this.tStatus = { id: true, text: 'Active' };
      if (dataItem.disabled) {
        this.tStatus = { id: false, text: 'Disabled' };
      }
      this.statusDialog.title = 'Edit ' + dataItem.displayName + ' Status';
      this.entity = this.$root.clone(dataItem);
      this.entity.status = this.status.find((x) => x.id == !dataItem.disabled);
      this.statusDialog.show = true;
    },
    showEditRoleDialog(dataItem) {
      this.roleDialog.title = 'Edit ' + dataItem.displayName + ' Role';
      this.entity = this.$root.clone(dataItem);
      this.roleDialog.show = true;
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    closeStatusDialog() {
      this.statusDialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    closeRoleDialog() {
      this.roleDialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    async save() {
      let formData = this.$root.clone(this.entity);
      if (formData.status) {
        formData.disabled = !formData.status.id;
      }
      formData.tenantId = this.tenantId;
      formData.lucaAccessible = this.entity.lucaAccessible.id;

      let url = '/person/add';
      if (this.entity.uid) {
        url = '/person/edit';
      }
      const response = await axios.post(url, formData);

      if (response.status == 200) {
        this.closeDialog();
        this.closeStatusDialog();
        this.closeRoleDialog();
        this.getPersonList();
        if (response.data.password && url === '/person/add') {
          this.notificationDialog.message = `<p>We have sent this user an email with instructions on how to setup their password. Please inform them to kindly click the link provided in the email to proceed.
            <span style="color: red;">Please keep in mind that that link will expire in 3 days.</span>  <br/><br/> If they haven't received our email, please tell them to check their Spam or Junk folder and mark us as 'Not Spam' to ensure future communications reach your inbox.</p>`;
          this.notificationDialog.show = true;
        }
      }
    },
    async batchEditStatus() {
      try {
        const promises = [];
        for (const person of this.personList) {
          promises.push(
            axios.post('/person/edit', {
              ...person,
              disabled: !this.entity?.status?.id || false,
              tenantId: this.tenantId
            })
          );
        }
        await Promise.all(promises);
        this.getPersonList();
      } catch (e) {
        console.log(e);
      } finally {
        this.closeBatchStatusDialog();
      }
    },
    showDeleteConfirmationDialog() {
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog.show = false;
    },
    async deleteUser() {
      try {
        await Promise.all(
          this.selectedItems.map((item) =>
            axios.post('/person/delete', {
              ...item,
              tenantId: this.tenantId
            })
          )
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.getPersonList();
        this.closeDeleteConfirmationDialog();
        this.resetSelected();
      }
    },
    showResetPasswordConfirmation() {
      this.resetPasswordConfirmationDialog.show = true;
    },
    showBatchEditStatusDialog() {
      this.batchStatusDialog.show = true;
    },
    isTenantManager(dataItem) {
      return dataItem.isManager;
    },
    async setUserAsTenantAdmin() {
      await this.handleUpdateTenantManager(
        this.tenantId,
        this.showSetTenantAdminPopup.userId,
        this.userLimit || 0
      );

      this.getPersonList();

      this.showSetTenantAdminPopup = {
        show: false,
        userId: ''
      };
    },
    async setUserLimit() {
      await this.handleUpdateTenantManager(
        this.tenantId,
        this.tenantManager || '',
        this.entityUsersLimit
      );
      this.getPersonList();
      this.showUpdateUsersLimitPopup = false;
    },
    closeResetPasswordConfirmation() {
      this.resetPasswordConfirmationDialog.show = false;
    },
    closeBatchStatusDialog() {
      this.batchStatusDialog.show = false;
    },

    async sendLinkResetPassword() {
      try {
        await Promise.all(
          this.selectedItems.map(({ email }) =>
            axios.post('/auth/forgotPassword', {
              email
            })
          )
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.closeResetPasswordConfirmation();
        this.resetSelected();
      }
    },
    resetSelected() {
      this.personList = this.personList.map((item) => ({
        ...item,
        selected: false
      }));
    },
    openPopupConfirmSetAsTenantManager(dataItem) {
      this.showSetTenantAdminPopup = {
        show: true,
        userId: dataItem.uid
      };
    },
    formatDate(date) {
      if (!date) return;
      return format(new Date(date), 'MMM dd, yyyy');
    },
    formatTime(date) {
      if (!date) return;
      return format(new Date(date), 'HH:MM:SS');
    },
    isTransparentlyEmail(email) {
      const splittedEmail = email.split('@') || [];
      const domain = splittedEmail?.[1];

      return TRANSPARENTLY_EMAIL_DOMAINS.includes(domain);
    },
    roleName(dataItem) {
      return (
        this.roleOptions?.find(({ value }) => value === dataItem?.role)
          ?.label || ''
      );
    }
  }
};
</script>
